import React, { useState } from 'react';
import { 
  BarChart, Bar, XAxis, YAxis, 
  CartesianGrid, Tooltip, Legend, 
  ResponsiveContainer 
} from 'recharts';
import { 
  Home, 
  BarChart2, 
  Calendar, 
  Users, 
  Settings, 
  ChevronRight, 
  ChevronLeft 
} from 'lucide-react';

const IncomeDashboard = () => {
  // State management
  const [selectedYears, setSelectedYears] = useState({
    2022: true,
    2023: true
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // Income data with multiple years
  const incomeData = {
    2022: [
      { month: 'Januari', value: 4017 },
      { month: 'Februari', value: 6135 },
      { month: 'Maret', value: 7091},
      { month: 'April', value: 5841 },
      { month: 'Mei', value: 5036 },
      { month: 'Juni', value: 4547 },
      { month: 'Juli', value: 3467 },
      { month: 'Agustus', value: 3970},
      { month: 'September', value: 6313 },
      { month: 'Oktober', value: 3595 },
      { month: 'November', value: 9207 },
      { month: 'Desember', value: 5945 }
    ],
    2023: [
      { month: 'Januari', value: 2416 },
      { month: 'Februari', value: 4136 },
      { month: 'Maret', value: 7935 },
      { month: 'April', value: 8004 },
      { month: 'Mei', value: 8004 },
      { month: 'Juni', value: 9505 },
      { month: 'Juli', value: 5026 },
      { month: 'Agustus', value: 6108 },
      { month: 'September', value: 6343 },
      { month: 'Oktober', value: 9404 },
      { month: 'November', value: 9280 },
      { month: 'Desember', value: 9287 }
    ]
  };

  // Format number with thousand separators and remove decimal
  const formatNumber = (num) => {
    return Math.floor(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  // Prepare chart data based on selected years
  const getChartData = () => {
    if (selectedYears[2022] && selectedYears[2023]) {
      return incomeData['2022'].map((item, index) => ({
        month: item.month,
        '2022': item.value,
        '2023': incomeData['2023'][index].value
      }));
    } else if (selectedYears[2022]) {
      return incomeData['2022'].map(item => ({ ...item, year: '2022' }));
    } else if (selectedYears[2023]) {
      return incomeData['2023'].map(item => ({ ...item, year: '2023' }));
    }
    return [];
  };

  // Calculate totals
  const calculateTotals = (year) => {
    const data = incomeData[year];
    const total = data.reduce((sum, item) => sum + item.value, 0);
    return {
      total: formatNumber(total),
      average: formatNumber(total / 12)
    };
  };

  const totals2022 = calculateTotals(2022);
  const totals2023 = calculateTotals(2023);

  // Handle year checkbox change
  const handleYearCheckboxChange = (year) => {
    setSelectedYears(prev => ({
      ...prev,
      [year]: !prev[year]
    }));
  };

  // Sidebar Menu Items
  const SidebarMenuItem = ({ icon: Icon, label, active }) => (
    <div className={`flex items-center p-3 cursor-pointer hover:bg-gray-100 ${active ? 'bg-blue-50 text-blue-600' : ''}`}>
      <Icon className="mr-3" size={20} />
      {isSidebarOpen && <span>{label}</span>}
    </div>
  );

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div 
        className={`bg-white border-r transition-all duration-300 ${
          isSidebarOpen ? 'w-64' : 'w-20'
        }`}
      >
        <div className="flex justify-between items-center p-4 border-b">
          {isSidebarOpen && <h2 className="text-xl font-bold">Dashboard</h2>}
          <button 
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="ml-auto"
          >
            {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
          </button>
        </div>

        <nav className="mt-4">
          <SidebarMenuItem icon={Home} label="Beranda" active={true} />
          <SidebarMenuItem icon={BarChart2} label="Laporan" />
          <SidebarMenuItem icon={Calendar} label="Kalender" />
          <SidebarMenuItem icon={Users} label="Pengguna" />
          <SidebarMenuItem icon={Settings} label="Pengaturan" />
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Navbar */}
        <nav className="bg-white border-b p-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold">Pendapatan Tahunan</h1>
        </nav>

        {/* Dashboard Content */}
        <div className="p-4 bg-gray-50 flex-1">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {/* Chart */}
            <div className="bg-white p-4 rounded shadow">
              {Object.values(selectedYears).some(Boolean) ? (
                <ResponsiveContainer width="100%" height={300}>
                  {selectedYears[2022] && selectedYears[2023] ? (
                    <BarChart data={getChartData()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis tickFormatter={formatNumber} />
                      <Tooltip formatter={(value) => [formatNumber(value), 'Pendapatan']} />
                      <Legend />
                      <Bar dataKey="2022" fill="#8884d8" name="2022" />
                      <Bar dataKey="2023" fill="#82ca9d" name="2023" />
                    </BarChart>
                  ) : selectedYears[2022] ? (
                    <BarChart data={getChartData()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis tickFormatter={formatNumber} />
                      <Tooltip formatter={(value) => [formatNumber(value), 'Pendapatan']} />
                      <Legend />
                      <Bar dataKey="value" fill="#8884d8" name="2022" />
                    </BarChart>
                  ) : selectedYears[2023] ? (
                    <BarChart data={getChartData()}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis tickFormatter={formatNumber} />
                      <Tooltip formatter={(value) => [formatNumber(value), 'Pendapatan']} />
                      <Legend />
                      <Bar dataKey="value" fill="#82ca9d" name="2023" />
                    </BarChart>
                  ) : null}
                </ResponsiveContainer>
              ) : (
                <div className="h-[300px] flex items-center justify-center text-gray-500">
                  Pilih setidaknya satu tahun untuk menampilkan grafik
                </div>
              )}

              {/* Year Selection Checkboxes */}
              <div className="flex justify-center mt-4 space-x-4">
                {[2022, 2023].map(year => (
                  <label key={year} className="flex items-center">
                    <input 
                      type="checkbox" 
                      checked={selectedYears[year]}
                      onChange={() => handleYearCheckboxChange(year)}
                      className="mr-2"
                    />
                    {year}
                  </label>
                ))}
              </div>
            </div>

            {/* Income Summary and Table */}
            <div className="bg-white p-4 rounded shadow">
              <div className="grid grid-cols-2 gap-4">
                {/* 2022 Summary */}
                <div>
                  <h3 className="text-lg font-semibold mb-2">Ringkasan 2022</h3>
                  <p>Total: Rp {totals2022.total}</p>
                  <p>Rata-rata: Rp {totals2022.average}</p>
                </div>
                {/* 2023 Summary */}
                <div>
                  <h3 className="text-lg font-semibold mb-2">Ringkasan 2023</h3>
                  <p>Total: Rp {totals2023.total}</p>
                  <p>Rata-rata: Rp {totals2023.average}</p>
                </div>
              </div>

              {/* Detailed Table */}
              <div className="mt-4">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border p-2">Bulan</th>
                      <th className="border p-2">2022</th>
                      <th className="border p-2">2023</th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomeData['2022'].map((item, index) => (
                      <tr key={item.month}>
                        <td className="border p-2">{item.month}</td>
                        <td className="border p-2 text-right">
                          Rp {formatNumber(item.value)}
                        </td>
                        <td className="border p-2 text-right">
                          Rp {formatNumber(incomeData['2023'][index].value)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeDashboard;