import React from 'react';
import IncomeDashboard from './IncomeDashboard';

function App() {
  return (
    <div className="App">
      <IncomeDashboard />
    </div>
  );
}

export default App;